body{
  background-image: linear-gradient(to right, #329d9c, #56c596);
}


.home_logo {
  display: block;
  width: 35vw;
  max-width: 280px;
  height: auto;
  margin: auto;
}

.home_form {
  text-align: center;
  align-self: center;
  margin-top: 2.5vh;
}

.home_textbox {
  border: 1px solid gray;
  text-align: center;
  width: 55vw; 
  max-width: 250px;
  height: 5vh;
  max-height: 50vh;
  border-top-right-radius: 0vw;
  border-top-left-radius: 0vw;
  border-bottom-left-radius: 1vw;
  border-bottom-right-radius: 1vw;
  color: #205072;
  font-weight: bold;
  margin-top: 1.5vh;
  margin-bottom: 1vh;
  background-color: rgb(229, 240, 233);
  font-size: 1.8vh;
}

.home_submit {
  border: 1px solid gray;
  text-align: center;
  margin: 0 auto;
  margin-top: 2.5vh;
  margin-bottom: 5vh;
  width: 20vw;
  max-width: 150px;
  height: 5vh;
  max-height: 100px;
  background-color: #cff4d2;
  color: #205072;
  border-top-right-radius: 0vw;
  border-top-left-radius: 0vw;
  border-bottom-left-radius: 2.5vw;
  border-bottom-right-radius: 2.5vw;
  font-weight: bold;
  cursor: pointer;
  font-size: 1.75vh;
}

.home_submit:hover {
  background-color: #7be495;
}

.home_textbox:hover {
  background-color: #7be495;
}

.guest {
  display: block;
  margin: auto;
  margin-top: 1vh;
  cursor: pointer;
  font-size: 1.8vh;
}

.loader {
  margin-top: 5vh;
}

.devnagari-text{
 
  margin-top: 10vw;
  font-size: 5vh;
  font-weight: bold;
  color: #205072;
  
}

@media screen and (max-width:1500px) and (min-width:1000px) {
  .home_textbox{
    width: 28vw;
    max-width: 350px;
    border-top-right-radius: 0vw;
    border-top-left-radius: 0vw;
    border-bottom-left-radius: 1.3vw;
    border-bottom-right-radius: 1.3vw
  }
  .home_submit{
    width: 15vw;
    max-width: 250px;
    border-top-right-radius: 0vw;
    border-top-left-radius: 0vw;
    border-bottom-left-radius: 4vw;
    border-bottom-right-radius: 4vw
  }
}

@media screen and (max-width:1000px) and (min-width:600px) {
  .home_textbox{
    width: 25vw;
    max-width: 350px; 
    height: 5vh;
    margin-top:0.2vh;
    border-top-right-radius: 0vw;
    border-top-left-radius: 0vw;
    border-bottom-left-radius: 2.2vw;
    border-bottom-right-radius: 2.2vw;
    font-size: 1.9vw;
   }
  .home_submit{
    font-size: 2vw;
    width: 15vw;
    height: 5vh;
    margin-top: 1.5vh;
    max-width: 250px;
    border-top-right-radius: 0vw;
    border-top-left-radius: 0vw;
    border-bottom-left-radius: 5vw;
    border-bottom-right-radius: 5vw
  }
}

@media screen and (max-width:600px) and (min-width:400px) {
  .home_textbox{
    width: 34vw;
    max-width: 350px; 
    height: 4vh;
    margin-top:0.2vh;
    border-top-right-radius: 0vw;
    border-top-left-radius: 0vw;
    border-bottom-left-radius: 2.5vw;
    border-bottom-right-radius: 2.5vw;
    font-size: 2.6vw;
   }
  .home_submit{
    font-size: 2.75vw;
    width: 19vw;
    height: 4vh;
    margin-top: 1.5vh;
    max-width: 250px;
    border-top-right-radius: 0vw;
    border-top-left-radius: 0vw;
    border-bottom-left-radius: 5.2vw;
    border-bottom-right-radius: 5.2vw
  }
}

@media screen and (max-width:400px)  {
  
  .home_textbox{
    width: 34vw;
    max-width: 350px; 
    height: 3.75vh;
    margin-top:0.2vh;
    border-top-right-radius: 0vw;
    border-top-left-radius: 0vw;
    border-bottom-left-radius: 3.5vw;
    border-bottom-right-radius: 3.5vw;
    font-size: 3vw;
   }
  .home_submit{
    font-size: 3vw;
    width: 20vw;
    height: 3.75vh;
    margin-top: 1vh;
    max-width: 250px;
    border-top-right-radius: 0vw;
    border-top-left-radius: 0vw;
    border-bottom-left-radius: 5vw;
    border-bottom-right-radius: 5vw
  }
  .home_logo {
    display: block;
    width: 37vw;
    max-width: 280px;
    margin-top: 25vh;
  }
}