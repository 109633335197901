.about-page{
  /* background-color: #205072; */
  background-color: #CFF4D2;
  width: 100%;
  min-height: 100vh;
  /* display: flex;
  flex-direction: column;
  flex-wrap: wrap; */
  justify-content: center;
  overflow-x: hidden;
}

.heading{ 
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  margin: 0 auto;
}

.heading h1{
  font-size: 8.5vh;
  color: #000;
  margin-bottom: 0.1vh;
  position: relative;
}

/* .heading h1::after {
  content:"";
  position: absolute;
  width: 100%;
  height: 1vh;
  display: block;
  margin: 0 auto;
  background-color: #CFF4D2;
  
} */

.container {
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  padding: 0;
  flex-wrap:wrap ;
}

.about{
  display: flex;
  justify-content: space-between;
  /* align-self: center; */
  flex-wrap: wrap-reverse;
}

.about-image{
  flex:1;
  margin-bottom: 2vh ;
  margin-right: -40vw;
  overflow: hidden; 
}

.about-image img{
  max-width: 35%;
  height: auto;
  display: block;
  transition: 0.5s ease;
}

.about-image:hover img{
  transform:scale(1.1 )
}

.about-content{
  flex:1;
  margin-left: 2vw;
  justify-content: space-between;
  /* margin-bottom: 2vh; */
}

.about-content h2{
  font-size: 4.5vh;
  margin-bottom: 0vh;
  margin-top: 4vh;
  color:black;
}

.about-content p{
  font-size: 2.4vh;
  /* margin-top: 1vh;
  margin-bottom: 1vh; */
  line-height:1.5;
  color: black; 
}

.section {
  margin:1vh;
  text-align: center;
}

.team{
  font-size: 5vh;
  color: #000;
  text-align: center;
  /* margin-bottom: 1vh;
  margin-top: 1vh; */
}

.team-box {
  width: 30%; 
  height: auto;
  max-width: 220px;
  margin: 0.75vw;
  margin-top: 0vh;
  margin-bottom: 2.5vh;
  color: black;
  padding: 1vw 0vh;
  border-radius: 2vh;
  border: 0.2vw solid black;
  text-align: center;
  perspective: 1000px;
  cursor: pointer;
  position: relative;
}

.team-box .team-member {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: transform 0.5s;
}

.team-box h3{
  font-size: 1.45vw;
  /* color: #205072; */
  color: black;
  margin-top: 0.25vh;
  padding: 0.5vw 1vh ;
}

.team-member-image {
  border-radius: 1.8vh; 
  width: 9vw; 
  max-width: 200px;
  height: 20vh; 
  margin-bottom: 1vh;
}

.team-box p{
  font-size: 1.2vw;
  color: #205072;
  margin-bottom: 2.5vh;
  margin-top: 0;
}

.team-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-bottom: 0vh;
  justify-content: space-around;
}


.icon-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  gap: 1vw; 
  margin-bottom: 0.75vh;
}

.icon-link {
  text-decoration: none;
  display: flex;
  height:auto;
  align-items: center; 
  color: black;
}

.icon-link:hover img {
  filter: brightness(80%); 
}

.footer {
  background-color: #205072;
  color: #fff;
  text-align: center;
  padding: 1vh;
  position: relative;
  bottom: 0;
  margin-top: 0;
  /* margin-left: 75px; */
  width: 100%;
}

.copyright {
  font-size: 1.8vh;
}


ul, ol {
  list-style-type: none;
  list-style-position:outside; 
  padding-left: 0; 
  font-size: 2.45vh;
  margin-top: 2.5vh;
}

li {
  margin-bottom: 0.1vh; 
}
li a{
  color:black;
}

li a:hover{
  color: #205072;
}



.about {
  display: flex;
  justify-content: space-between;
  align-self: center;
  flex-wrap: wrap-reverse;
}

.about-content,
.about-image {
  flex: 1;
  margin-bottom: 2vh;
}


/*For smaller screens*/
@media screen and (max-width: 1500px) and (min-width:1050px) {
  .about {
    flex-direction: column-reverse;
  }
  .about-content{
    text-align: justify;
    hyphens: auto;
  }
  .about-image {
    flex: none;
    align-self: center;
    width: 100%;
    margin-bottom: -2vh;
  }
  .team-container {
    margin-left: 5vw;
    margin-right: 5vw;
  }
  .team-box {
    width: 50%; 
    margin-left: 0vw;
  }
  .team-box h3{
    font-size: 3vh;
  }
  .team-box p{
    font-size: 3vh;
  }
  .team-member-image{
    width: 12vw;
    height: 12vw;
  }
}

@media screen and (max-width: 850px) {
  .about {
    flex-direction: column-reverse;
  }

  .about-content{
    text-align: justify;
    hyphens: auto;
  }

  .about-image {
    flex: none;
    align-self: center;
    width: 100%;
    margin-bottom: -2vh;
  }
  .team-container {
    margin-left: 5vw;
    margin-right: 5vw;
  }

  .team-box {
    width: 50%; 
  }
  .team-box h3{
    font-size: 2vh;
  }
  .team-box p{
    font-size: 2vh;
  }
  .arrow {
    font-size: 4vw;  
    margin-right: 0.5vw;
    display: flex;
  }
}
