.Chat {
  text-align: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.chatbox {
  flex: 1;
  background-color: #eaecef;
  width: 100%;
  max-width: none;
}

/* .logo {
  color: white;
}

.sidebar {
  width: 260px;
  padding: 10px;
  background-image: linear-gradient(to bottom, #205072, #329d9c, #56c596);
  color: white;
}



.sidebar-button {
  padding: 10px;
  border: 1px solid white;
  border-radius: 5px;
  text-align: left;
  transition: ease 0.25s all;
}

.sidebar-button:hover {
  background-color: #438285;
}

.sidebar-button span {
  padding: 0 12px 0 6px;
}

.chat-inputbox {
  padding: 26px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.form-inputbox {
  display: flex;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.input-textarea {
  flex: 95%;
  border-radius: 8px;
  border: none;
  padding: 20px;
  padding-right: 55px;
  margin: 12px;
  outline: none;
  font-size: 1.2em;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
}

input[type="submit"] {
  flex: 5%;
  font-family: FontAwesome;
  border: none;
  margin-left: -85px;
  background: none;
  font-size: larger;
  color: #317b7d;

  cursor: pointer;
}

input[type="submit"]:hover {
  color: rgb(75, 129, 118);
  font-size: x-large;
}

.chat-query {
  margin-bottom: 15px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 4px 4px 0px 0px rgba(218, 213, 213, 0.25);

  display: flex;
  padding: 12px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 640px;
  margin-left: auto;
}

.avatar {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 50%;
  background: #ffffff;
  width: 40px;
  height: 40px;
  text-align: center;
}

.message {
  padding: 10px;
  padding-left: 20px;
  line-height: 25px;
}

.chat-answer {
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 4px 4px 0px 0px rgba(218, 213, 213, 0.25);
  background-color: #cbe5e6;

  display: flex;
  padding: 12px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 640px;
  margin-right: auto;
}

.source {
  padding: 10px;
  border: 1px solid #317b7d;
  border: none;
  border-radius: 20px;
} */
