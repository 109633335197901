.chat-log {
  /* width: 100vw;
  height: 100vh; */
  padding: 1.5vw;
  padding-right: 10%;
  padding-left: 10%;
  text-align: left;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;
  /* overflow-y: scroll; */
  overscroll-behavior-y: contain;
  scroll-snap-type: y proximity;
  max-height: 90vh;
}

.chatbox {
  flex: 1;
  width: 100%;
}

.chat-title {
  margin-top: 5vh;
  font-size: 3vw;
  font-weight: bold;
  font-family:cursive;
  color: #205072;
  text-align: center;
  margin-bottom: 2vh;
  
}

.chat-query {
  background-color: white;
  border-radius: 0.85vw;
  box-shadow: 0.4vw 0.5vh 0vw 0vh rgba(218, 213, 213, 0.25);
  display: flex;
  padding: 0.5vw;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  max-width: 640px;
  margin-left: auto;
  margin-top: 1vh;
  margin-bottom: 1vh;
  margin-right: 0.1vw;
  font-size: 1.5vw;
}

.chat-answer {
  border-radius: 0.85vw;
  box-shadow: 0.4vw 0.75vh 0vw 0vh rgba(218, 213, 213, 0.25);
  background-color: #cbe5e6;
  display: flex;
  padding: 0.5vw;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  width: 65%;
  max-width: 75vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
  margin-right: auto;
  margin-left: 1vw;
  font-size: 1.2vw;
}

.avatar {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 50%;
  background: #ffffff;
  width: 45px;
  height: 43px;
  text-align: center;
  align-self: center;
}

#p_wrap {
  white-space: pre-line;
}

.message {
  padding: 0.5vh;
  padding-left: 2.5vw;
  line-height: 3.1vh;
}

/* .question-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1vw;
  margin-top: 30vh;
  margin-bottom: 0.25vh;
}

.question-box {
  cursor: pointer;
  width: 25vw;
  height: 15vh;
  padding: 1vw;
  margin: 0.5vh;
  border: 0.2vw solid #205072;
  border-radius: 1vw;
  background-color: #eaecef;
  transition: background-color 0.3s;
  box-sizing: border-box;
}

.question-box:hover {
  background-color: whitesmoke;
} */

@media screen and (max-width: 1500px) and (min-width: 1000px){
  .chat-answer{
    font-size: 1.7vw;
  }
  .chat-query{
    font-size: 1.8vw;
  }
 
}

@media screen and (max-width: 1000px) and (min-width: 768px){
  .chat-answer{
    font-size: 20px;
    width: 55vw;
    margin-bottom: 10vh;
    margin-left: 18vw;
  }
  .chat-query{
    font-size: 25px;
  }
  .avatar{
    width:5vw;
    height: 3.5vh;
  }
}

@media screen and (max-width: 768px) and (min-width:500px) {
  .chat-answer{
    font-size: 3vw;
    width: 60vw;
    margin-bottom: 8vh;
    margin-left: 7.5vw;
  }
  .chat-query{
    font-size: 3vw;
  }
  .avatar{
    width:45px;
    height: 45px;
  }
}

@media screen and (max-width: 500px) and (min-width:300px) {
  .chat-answer{
    font-size: 15px;
    width: 60vw;
    margin-bottom: 8vh;
    margin-left: 10vw;
  }
  .chat-query{
    font-size: 15px;
    text-align: center;
  }
  .avatar{
    width:45px;
    height: 45px;
  }
}

@media screen and (max-width: 350px)  {
  .chat-answer{
    font-size: 13px;
    width: 60vw;
    margin-bottom: 8vh;
    margin-left: 18vw;
  }
  .chat-query{
    font-size: 15px;
    text-align: center;
  }
  .avatar{
    width:45px;
    height: 45px;
  }
}
